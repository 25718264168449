import IComponentPool from 'core/Interfaces/IComponentPool';
import { Alert } from "react-bootstrap";
import Accordion from "./Accordion";
import CardSimple from './CardSimple';


const components: IComponentPool = {
    Alert,
    Accordion,
    CardSimple
}

export default components;
