import IComponentPool from 'core/Interfaces/IComponentPool';
import { Button, Badge } from "react-bootstrap";
import TextFromPromise from './TextFromPromise';


const components: IComponentPool = {
    Button,
    Badge,
    TextFromPromise
}

export default components;
