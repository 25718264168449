import IComponentPool from 'core/Interfaces/IComponentPool';
import basic from './basic';
import form from './form';
import container from './container';

const components: IComponentPool = {
    form,
    basic,
    container
}

export default components;
